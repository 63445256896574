// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';



export class Scan implements flatbuffers.IUnpackableObject<ScanT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Scan {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsScan(bb:flatbuffers.ByteBuffer, obj?:Scan):Scan {
  return (obj || new Scan()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsScan(bb:flatbuffers.ByteBuffer, obj?:Scan):Scan {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Scan()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

latitude():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

longitude():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

altitude():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

timestampSinceRef():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readFloat64(this.bb_pos + offset) : 0.0;
}

address():string|null
address(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
address(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

title():string|null
title(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
title(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

description():string|null
description(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
description(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

device():string|null
device(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
device(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

notes():string|null
notes(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
notes(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 22);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

static startScan(builder:flatbuffers.Builder) {
  builder.startObject(10);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(0, version, 0);
}

static addLatitude(builder:flatbuffers.Builder, latitude:number) {
  builder.addFieldFloat64(1, latitude, 0.0);
}

static addLongitude(builder:flatbuffers.Builder, longitude:number) {
  builder.addFieldFloat64(2, longitude, 0.0);
}

static addAltitude(builder:flatbuffers.Builder, altitude:number) {
  builder.addFieldFloat64(3, altitude, 0.0);
}

static addTimestampSinceRef(builder:flatbuffers.Builder, timestampSinceRef:number) {
  builder.addFieldFloat64(4, timestampSinceRef, 0.0);
}

static addAddress(builder:flatbuffers.Builder, addressOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, addressOffset, 0);
}

static addTitle(builder:flatbuffers.Builder, titleOffset:flatbuffers.Offset) {
  builder.addFieldOffset(6, titleOffset, 0);
}

static addDescription(builder:flatbuffers.Builder, descriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(7, descriptionOffset, 0);
}

static addDevice(builder:flatbuffers.Builder, deviceOffset:flatbuffers.Offset) {
  builder.addFieldOffset(8, deviceOffset, 0);
}

static addNotes(builder:flatbuffers.Builder, notesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(9, notesOffset, 0);
}

static endScan(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createScan(builder:flatbuffers.Builder, version:number, latitude:number, longitude:number, altitude:number, timestampSinceRef:number, addressOffset:flatbuffers.Offset, titleOffset:flatbuffers.Offset, descriptionOffset:flatbuffers.Offset, deviceOffset:flatbuffers.Offset, notesOffset:flatbuffers.Offset):flatbuffers.Offset {
  Scan.startScan(builder);
  Scan.addVersion(builder, version);
  Scan.addLatitude(builder, latitude);
  Scan.addLongitude(builder, longitude);
  Scan.addAltitude(builder, altitude);
  Scan.addTimestampSinceRef(builder, timestampSinceRef);
  Scan.addAddress(builder, addressOffset);
  Scan.addTitle(builder, titleOffset);
  Scan.addDescription(builder, descriptionOffset);
  Scan.addDevice(builder, deviceOffset);
  Scan.addNotes(builder, notesOffset);
  return Scan.endScan(builder);
}

unpack(): ScanT {
  return new ScanT(
    this.version(),
    this.latitude(),
    this.longitude(),
    this.altitude(),
    this.timestampSinceRef(),
    this.address(),
    this.title(),
    this.description(),
    this.device(),
    this.notes()
  );
}


unpackTo(_o: ScanT): void {
  _o.version = this.version();
  _o.latitude = this.latitude();
  _o.longitude = this.longitude();
  _o.altitude = this.altitude();
  _o.timestampSinceRef = this.timestampSinceRef();
  _o.address = this.address();
  _o.title = this.title();
  _o.description = this.description();
  _o.device = this.device();
  _o.notes = this.notes();
}
}

export class ScanT implements flatbuffers.IGeneratedObject {
constructor(
  public version: number = 0,
  public latitude: number = 0.0,
  public longitude: number = 0.0,
  public altitude: number = 0.0,
  public timestampSinceRef: number = 0.0,
  public address: string|Uint8Array|null = null,
  public title: string|Uint8Array|null = null,
  public description: string|Uint8Array|null = null,
  public device: string|Uint8Array|null = null,
  public notes: string|Uint8Array|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const address = (this.address !== null ? builder.createString(this.address!) : 0);
  const title = (this.title !== null ? builder.createString(this.title!) : 0);
  const description = (this.description !== null ? builder.createString(this.description!) : 0);
  const device = (this.device !== null ? builder.createString(this.device!) : 0);
  const notes = (this.notes !== null ? builder.createString(this.notes!) : 0);

  return Scan.createScan(builder,
    this.version,
    this.latitude,
    this.longitude,
    this.altitude,
    this.timestampSinceRef,
    address,
    title,
    description,
    device,
    notes
  );
}
}
