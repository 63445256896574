import { firebaseStorage } from './_storage'
import { User } from 'firebase/auth'
import { Modal } from 'bootstrap'
import { getStorage, ref, getBytes, getDownloadURL } from "firebase/storage"
import { collection, doc, getDoc, setDoc } from "firebase/firestore"
import { getAuth } from "firebase/auth"
import { firebaseDb } from './_database'
import { setText, isLocalHost } from '../_utils'
import { Scan, ScanT } from '../flatbuffers/fbfloorplanner/scan'
import { Model } from '../flatbuffers/fbfloorplanner/model'
import {} from "firebase/firestore"
import * as flatbuffers from 'flatbuffers';
import { FirebaseError } from 'firebase/app'

// A texture waiting for be loaded.
type PendingTexture = {
    atlasIndex: number;
    url: string;
};

// Some global variables for the bridge between C++ and javascript.
var pendingBytesArray: ArrayBuffer[] = [];
var pendingTextures: PendingTexture[] = [];
var imageSpaceReady = false;
var context: WebGL2RenderingContext | null = null;

// These are exported from floorplanner/Web/post.js.
// These functions bridge C++ and javascript.
declare global {
    interface Window {
        imageSpaceReady: boolean|null;
        setImageSpaceData:(data: ArrayBuffer) => ArrayBuffer
        getContext:() => WebGL2RenderingContext|null
        setTexture:(atlasIndex: number, texture: WebGLTexture, width: number, height: number) => void
        setOnModuleReadyCallback:(callback: () => void) => void
        onImageSpaceReady: () => void;
    }
}

// Add this function
function createTextureFromURL(gl: WebGL2RenderingContext, atlasIndex: number, url: string) {
    const image = new Image();
    image.crossOrigin = "anonymous"; // Enable CORS if the image is from a different domain

    image.onload = function() {
        const texture = gl.createTexture();
        if (texture) {
            gl.bindTexture(gl.TEXTURE_2D, texture);
            gl.texImage2D(gl.TEXTURE_2D, 0, gl.SRGB8_ALPHA8, gl.RGBA, gl.UNSIGNED_BYTE, image);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
            gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
            window.setTexture(atlasIndex, texture, image.width, image.height);
        } else {
            console.error("Error creating texture for atlas", atlasIndex);
        }
    };

    image.onerror = function() {
        console.error("Error loading texture:", url);
    }

    // Set the src after defining onload and onerror
    image.src = url;
}

// Assign the function to the window object
window.onImageSpaceReady = () => {
    console.log('Module is ready');
    imageSpaceReady = true;

    context = window.getContext();
    if (context == null) {
        console.error('No context found');
        return;
    }

    for (const bytes of pendingBytesArray) {
        window.setImageSpaceData(bytes);
    }

    if (context) {
        for (const texture of pendingTextures) {
            createTextureFromURL(context, texture.atlasIndex, texture.url);
        }
    }

    pendingTextures = [];
    pendingBytesArray = [];
};

function dispatchFlatbuffer(bytes: ArrayBuffer) {
    if (imageSpaceReady) {
        window.setImageSpaceData(bytes);
    } else {
        pendingBytesArray.push(bytes);
    }
}

function dispatchTexture(atlasIndex: number, url: string) {
    if (imageSpaceReady) {
        if (context) {
            createTextureFromURL(context, atlasIndex, url);
        }
    } else {
        // Start prefetching the texture URL
        const prefetchLink = document.createElement('link');
        prefetchLink.rel = 'prefetch';
        prefetchLink.href = url;
        document.head.appendChild(prefetchLink);

        // Remove the existing pending texture for this atlas index.
        pendingTextures = pendingTextures.filter(texture => texture.atlasIndex != atlasIndex);

        // Add the new pending texture.
        pendingTextures.push({
            atlasIndex,
            url
        });
    }
}

function showError(error: FirebaseError) {
    var element = document.getElementById('errorModal');
    if (element) {
        var errorModal = new Modal(element);
        setText('errorMessage', error.message);
        errorModal.show();
    }
}

function showProgress() {
    var element = document.getElementById('processingModal');
    if (element) {
        var processingModal = new Modal(element);
        processingModal.show();
    }
}


// Called when the hover ID changes.
function onHoverIdChanged(newId: number) {
}

// Called when the flags change.
function onOutFlagsChanged(newFlags: number) {
}

  
function mapUrlForLatLong(latitude: number, longitude: number, address: string | null) {
    return "https://www.google.com/maps/search/?api=1&query=" + latitude + "," + longitude;
}   

async function loadScanData(scanId: string) {
    console.log('loadScanData');

    // If the WASM module was initialized we need to call imageSpaceManually.
    if ((window.imageSpaceReady == true) && (imageSpaceReady == false)) {
        window.onImageSpaceReady();
    }

    // Get the model.
    getBytes(ref(firebaseStorage, 'scans/' + scanId + '/model.fb')).then((bytes: ArrayBuffer) => {
        dispatchFlatbuffer(bytes);
        document.getElementById('loadingSpinner')?.classList.add('d-none');

        // bytes is the Model flatbuffer.
        var model = Model.getRootAsModel(new flatbuffers.ByteBuffer(new Uint8Array(bytes)));

        // Function to load textures
        const loadTextures = (resolution: string) => {
            const promises = [];
            for (let atlasIndex = 0; atlasIndex < model.atlassesLength(); atlasIndex++) {
                const fileName = `scans/${scanId}/Textures/atlas_${atlasIndex}${resolution}.webp`;
                const promise = getDownloadURL(ref(firebaseStorage, fileName))
                    .then(webpURL => {
                        dispatchTexture(atlasIndex, webpURL);
                    })
                    .catch(error => {
                        console.error(`Error loading ${resolution === '_4' ? 'low-res' : 'high-res'} texture for atlas ${atlasIndex}:`, error);
                        // Attempt to load a placeholder texture or skip this texture
                        return Promise.resolve();

                    });
                promises.push(promise);
            }
            return Promise.all(promises);
        };

        // Load low resolution textures first, then high resolution
        loadTextures('_4')
            .then(() => {
                console.log("Low-res textures loaded. Waiting 1 second before loading high-res textures...");
                return new Promise(resolve => setTimeout(resolve, 1000));
            })
            .then(() => {
                console.log("Loading high-res textures...");
                return loadTextures('');
            })
            .then(() => {
                console.log("All textures loaded successfully.");
            })
            .catch(error => {
                console.error("Error in texture loading process:", error);
            });
    })
    .catch((error) => {
        showError(error);
    });

    // Get the structure.
    getBytes(ref(firebaseStorage, 'scans/' + scanId + '/structure.fb')).then((bytes: ArrayBuffer) => {
        dispatchFlatbuffer(bytes);
    })
    .catch((error) => {
        showError(error);
    })

    // Get the ann.
    getBytes(ref(firebaseStorage, 'scans/' + scanId + '/annotations.fb')).then((bytes: ArrayBuffer) => {
        dispatchFlatbuffer(bytes);
    })
    .catch((error) => {
        showError(error);
    })

    // Read the scan data.
    getBytes(ref(firebaseStorage, 'scans/' + scanId + '/' + 'scan.fb')).then((bytes: ArrayBuffer) => {

        // Send it to the display.
        dispatchFlatbuffer(bytes);

        // But also populate the scan details overlay.
        let scan = Scan.getRootAsScan(new flatbuffers.ByteBuffer(new Uint8Array(bytes)));
                        
        // Reference time in JavaScript (January 1, 1970, 00:00:00 UTC)
        const referenceTime = new Date('January 1, 2001 00:00:00 UTC').getTime();

        // Convert macOS duration from seconds to milliseconds
        const macOSDurationMs = scan.timestampSinceRef() * 1000;

        // Add macOS duration to reference time in JavaScript
        const resultTime = new Date(referenceTime + macOSDurationMs);

        // Format the address line.
        if ((scan.latitude() != 0) && (scan.longitude() != 0)) {
            let url = mapUrlForLatLong(scan.latitude(), scan.longitude(), scan.address());

            var adress = "Unknown address";
            if (scan.address()) {
                adress = "<a href=" + url + " target=\"_blank\">" + scan.address() + "</a>";
            } else {
                adress = "<a href=" + url + " target=\"_blank\">Unknown address</a>";
            }
            setText('scanAddress', adress);
        } else {
            document.getElementById('scanAddress')?.classList.add('d-none');
        }

        const options: Intl.DateTimeFormatOptions = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };

        setText('scanTitle', scan.title() ?? "Untitled scan");
        setText('scanDateTime', resultTime.toLocaleString(undefined, options));
        document.getElementById('scanDetails')?.classList.remove('d-none');
    }).catch((error) => {
        if (error.code == 'storage/object-not-found') {
            window.location.href = "/404.html";
        } else {
            showError(error);
        }
    });
}


async function checkProgress(scanId: string, modal: Modal|null) {
    const scanDoc = await getDoc(doc(firebaseDb, 'scans', scanId));
    if (scanDoc.exists() && (scanDoc.data()['status'] == 'processing')) {
        if (modal == null) {
            var element = document.getElementById('processingModal');
            if (element) {
                modal = new Modal(element);
                modal.show();
            }
        }

        // Wait for 2 seconds and check again.
        setTimeout(async () => {
            await checkProgress(scanId, modal);
        }, 2000);
    } else {

        // Kill the modal.
        modal?.hide();

        // Readt to download the scan data.
        loadScanData(scanId);
    }
}

export async function loadScan(scanId: string) {
    console.log("Loading scan " + scanId);
    
    // Get the scans from the database.
    getDoc(doc(firebaseDb, 'scans', scanId)).then((scanDoc) => {
        if (scanDoc.exists()) {

            // Is the scan processed already?
            if (scanDoc.data()['status'] == 'processed') {
                loadScanData(scanId);
            } else {
                checkProgress(scanId, null);
            }

            // Get the user profile image.
            getDownloadURL(ref(firebaseStorage, "users/" + scanDoc.data()['uid'] + "/profile_4.webp"))
            .then((url) => {
                setText('scanUserIcon', url);
            })
            .catch((error) => {
                console.log("User profile image not found:", error);
            });

            // Get the user data.
            getDoc(doc(firebaseDb, 'users', scanDoc.data()['uid'])).then((userDoc) => {
                if (userDoc.exists()) {
                    setText("scanUserName", userDoc.data()['displayName']);
                }
            });
        } else {
            // Redirect to 404
            window.location.href = "/404.html";
        }
    });

}

export function updateScans(user: User | null) {
    /*
    if (user != null) {
        var db = firebase.firestore();
        var scansCollection = db.collection("users").doc(user.uid).collection("scans");
        scansCollection.get().then((querySnapshot) => {
            var innerHtml = '';
            var storage = firebase.storage();
            var modelsRef = storage.ref('models');
            querySnapshot.forEach((doc) => {
                var scanRef = modelsRef.child(doc.id);
                var thumbRef = scanRef.child('thumb.jpg');
                thumbRef.getDownloadURL().then((url) => {
                    $("#" + doc.id).html('<img src="' + url + '" />');
                });
                innerHtml += '<div class="modelThumbCard"><a id="' + doc.id + '" href="#" class="modelThumb"></a>';
                innerHtml += '<span>' + doc.data().UploadDateTime + '</span>';
                innerHtml += '</a></div>';
            });
            setScansHtml(innerHtml);

            $('.modelThumb').on("click", function () {
                loadModel($(this).attr("id") ?? "");
                hideScans();
            });

        }).catch((error: any) => {
            console.log("Error getting cached document:", error);
        });


    } else {

        setScansHtml('');
    }
    */
}

// export function initScans() {
//     hideScans();
// }