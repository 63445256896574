// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Atlas, AtlasT } from '../fbfloorplanner/atlas.js';
import { ModelMesh, ModelMeshT } from '../fbfloorplanner/model-mesh.js';
import { Volume, VolumeT } from '../fbfloorplanner/volume.js';


export class Model implements flatbuffers.IUnpackableObject<ModelT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Model {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsModel(bb:flatbuffers.ByteBuffer, obj?:Model):Model {
  return (obj || new Model()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsModel(bb:flatbuffers.ByteBuffer, obj?:Model):Model {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Model()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static bufferHasIdentifier(bb:flatbuffers.ByteBuffer):boolean {
  return bb.__has_identifier('MODL');
}

version():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

volume(obj?:Volume):Volume|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? (obj || new Volume()).__init(this.bb_pos + offset, this.bb!) : null;
}

vertices():string|null
vertices(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
vertices(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

indices(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint16(this.bb!.__vector(this.bb_pos + offset) + index * 2) : 0;
}

indicesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

indicesArray():Uint16Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? new Uint16Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

atlasses(index: number, obj?:Atlas):Atlas|null {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? (obj || new Atlas()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

atlassesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

meshes(index: number, obj?:ModelMesh):ModelMesh|null {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? (obj || new ModelMesh()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

meshesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

atlasResolution():number {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startModel(builder:flatbuffers.Builder) {
  builder.startObject(7);
}

static addVersion(builder:flatbuffers.Builder, version:number) {
  builder.addFieldInt32(0, version, 0);
}

static addVolume(builder:flatbuffers.Builder, volumeOffset:flatbuffers.Offset) {
  builder.addFieldStruct(1, volumeOffset, 0);
}

static addVertices(builder:flatbuffers.Builder, verticesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, verticesOffset, 0);
}

static addIndices(builder:flatbuffers.Builder, indicesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, indicesOffset, 0);
}

static createIndicesVector(builder:flatbuffers.Builder, data:number[]|Uint16Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createIndicesVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createIndicesVector(builder:flatbuffers.Builder, data:number[]|Uint16Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(2, data.length, 2);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addInt16(data[i]!);
  }
  return builder.endVector();
}

static startIndicesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(2, numElems, 2);
}

static addAtlasses(builder:flatbuffers.Builder, atlassesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(4, atlassesOffset, 0);
}

static createAtlassesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startAtlassesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addMeshes(builder:flatbuffers.Builder, meshesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(5, meshesOffset, 0);
}

static createMeshesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startMeshesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static addAtlasResolution(builder:flatbuffers.Builder, atlasResolution:number) {
  builder.addFieldInt32(6, atlasResolution, 0);
}

static endModel(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishModelBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'MODL');
}

static finishSizePrefixedModelBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, 'MODL', true);
}


unpack(): ModelT {
  return new ModelT(
    this.version(),
    (this.volume() !== null ? this.volume()!.unpack() : null),
    this.vertices(),
    this.bb!.createScalarList<number>(this.indices.bind(this), this.indicesLength()),
    this.bb!.createObjList<Atlas, AtlasT>(this.atlasses.bind(this), this.atlassesLength()),
    this.bb!.createObjList<ModelMesh, ModelMeshT>(this.meshes.bind(this), this.meshesLength()),
    this.atlasResolution()
  );
}


unpackTo(_o: ModelT): void {
  _o.version = this.version();
  _o.volume = (this.volume() !== null ? this.volume()!.unpack() : null);
  _o.vertices = this.vertices();
  _o.indices = this.bb!.createScalarList<number>(this.indices.bind(this), this.indicesLength());
  _o.atlasses = this.bb!.createObjList<Atlas, AtlasT>(this.atlasses.bind(this), this.atlassesLength());
  _o.meshes = this.bb!.createObjList<ModelMesh, ModelMeshT>(this.meshes.bind(this), this.meshesLength());
  _o.atlasResolution = this.atlasResolution();
}
}

export class ModelT implements flatbuffers.IGeneratedObject {
constructor(
  public version: number = 0,
  public volume: VolumeT|null = null,
  public vertices: string|Uint8Array|null = null,
  public indices: (number)[] = [],
  public atlasses: (AtlasT)[] = [],
  public meshes: (ModelMeshT)[] = [],
  public atlasResolution: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const vertices = (this.vertices !== null ? builder.createString(this.vertices!) : 0);
  const indices = Model.createIndicesVector(builder, this.indices);
  const atlasses = Model.createAtlassesVector(builder, builder.createObjectOffsetList(this.atlasses));
  const meshes = Model.createMeshesVector(builder, builder.createObjectOffsetList(this.meshes));

  Model.startModel(builder);
  Model.addVersion(builder, this.version);
  Model.addVolume(builder, (this.volume !== null ? this.volume!.pack(builder) : 0));
  Model.addVertices(builder, vertices);
  Model.addIndices(builder, indices);
  Model.addAtlasses(builder, atlasses);
  Model.addMeshes(builder, meshes);
  Model.addAtlasResolution(builder, this.atlasResolution);

  return Model.endModel(builder);
}
}
