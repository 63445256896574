// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';



export class ModelMesh implements flatbuffers.IUnpackableObject<ModelMeshT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):ModelMesh {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsModelMesh(bb:flatbuffers.ByteBuffer, obj?:ModelMesh):ModelMesh {
  return (obj || new ModelMesh()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsModelMesh(bb:flatbuffers.ByteBuffer, obj?:ModelMesh):ModelMesh {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new ModelMesh()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

atlasIndex():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

startVertex():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

startIndex():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

numVertices():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

numIndices():number {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

static startModelMesh(builder:flatbuffers.Builder) {
  builder.startObject(5);
}

static addAtlasIndex(builder:flatbuffers.Builder, atlasIndex:number) {
  builder.addFieldInt16(0, atlasIndex, 0);
}

static addStartVertex(builder:flatbuffers.Builder, startVertex:number) {
  builder.addFieldInt32(1, startVertex, 0);
}

static addStartIndex(builder:flatbuffers.Builder, startIndex:number) {
  builder.addFieldInt32(2, startIndex, 0);
}

static addNumVertices(builder:flatbuffers.Builder, numVertices:number) {
  builder.addFieldInt32(3, numVertices, 0);
}

static addNumIndices(builder:flatbuffers.Builder, numIndices:number) {
  builder.addFieldInt32(4, numIndices, 0);
}

static endModelMesh(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createModelMesh(builder:flatbuffers.Builder, atlasIndex:number, startVertex:number, startIndex:number, numVertices:number, numIndices:number):flatbuffers.Offset {
  ModelMesh.startModelMesh(builder);
  ModelMesh.addAtlasIndex(builder, atlasIndex);
  ModelMesh.addStartVertex(builder, startVertex);
  ModelMesh.addStartIndex(builder, startIndex);
  ModelMesh.addNumVertices(builder, numVertices);
  ModelMesh.addNumIndices(builder, numIndices);
  return ModelMesh.endModelMesh(builder);
}

unpack(): ModelMeshT {
  return new ModelMeshT(
    this.atlasIndex(),
    this.startVertex(),
    this.startIndex(),
    this.numVertices(),
    this.numIndices()
  );
}


unpackTo(_o: ModelMeshT): void {
  _o.atlasIndex = this.atlasIndex();
  _o.startVertex = this.startVertex();
  _o.startIndex = this.startIndex();
  _o.numVertices = this.numVertices();
  _o.numIndices = this.numIndices();
}
}

export class ModelMeshT implements flatbuffers.IGeneratedObject {
constructor(
  public atlasIndex: number = 0,
  public startVertex: number = 0,
  public startIndex: number = 0,
  public numVertices: number = 0,
  public numIndices: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return ModelMesh.createModelMesh(builder,
    this.atlasIndex,
    this.startVertex,
    this.startIndex,
    this.numVertices,
    this.numIndices
  );
}
}
