// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';



export class Atlas implements flatbuffers.IUnpackableObject<AtlasT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Atlas {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAtlas(bb:flatbuffers.ByteBuffer, obj?:Atlas):Atlas {
  return (obj || new Atlas()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAtlas(bb:flatbuffers.ByteBuffer, obj?:Atlas):Atlas {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new Atlas()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

startMesh():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
}

numMeshes():number {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
}

static startAtlas(builder:flatbuffers.Builder) {
  builder.startObject(2);
}

static addStartMesh(builder:flatbuffers.Builder, startMesh:number) {
  builder.addFieldInt32(0, startMesh, 0);
}

static addNumMeshes(builder:flatbuffers.Builder, numMeshes:number) {
  builder.addFieldInt16(1, numMeshes, 0);
}

static endAtlas(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createAtlas(builder:flatbuffers.Builder, startMesh:number, numMeshes:number):flatbuffers.Offset {
  Atlas.startAtlas(builder);
  Atlas.addStartMesh(builder, startMesh);
  Atlas.addNumMeshes(builder, numMeshes);
  return Atlas.endAtlas(builder);
}

unpack(): AtlasT {
  return new AtlasT(
    this.startMesh(),
    this.numMeshes()
  );
}


unpackTo(_o: AtlasT): void {
  _o.startMesh = this.startMesh();
  _o.numMeshes = this.numMeshes();
}
}

export class AtlasT implements flatbuffers.IGeneratedObject {
constructor(
  public startMesh: number = 0,
  public numMeshes: number = 0
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Atlas.createAtlas(builder,
    this.startMesh,
    this.numMeshes
  );
}
}
