// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Box3, Box3T } from '../fbmath/box3.js';
import { TRXform2D5, TRXform2D5T } from '../fbmath/trxform2-d5.js';


export class Volume implements flatbuffers.IUnpackableObject<VolumeT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Volume {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

xform(obj?:TRXform2D5):TRXform2D5|null {
  return (obj || new TRXform2D5()).__init(this.bb_pos, this.bb!);
}

bounds(obj?:Box3):Box3|null {
  return (obj || new Box3()).__init(this.bb_pos + 16, this.bb!);
}

static sizeOf():number {
  return 40;
}

static createVolume(builder:flatbuffers.Builder, xform_translation_x: number, xform_translation_y: number, xform_translation_z: number, xform_orientation: number, bounds_min_x: number, bounds_min_y: number, bounds_min_z: number, bounds_max_x: number, bounds_max_y: number, bounds_max_z: number):flatbuffers.Offset {
  builder.prep(4, 40);
  builder.prep(4, 24);
  builder.prep(4, 12);
  builder.writeFloat32(bounds_max_z);
  builder.writeFloat32(bounds_max_y);
  builder.writeFloat32(bounds_max_x);
  builder.prep(4, 12);
  builder.writeFloat32(bounds_min_z);
  builder.writeFloat32(bounds_min_y);
  builder.writeFloat32(bounds_min_x);
  builder.prep(4, 16);
  builder.writeFloat32(xform_orientation);
  builder.prep(4, 12);
  builder.writeFloat32(xform_translation_z);
  builder.writeFloat32(xform_translation_y);
  builder.writeFloat32(xform_translation_x);
  return builder.offset();
}


unpack(): VolumeT {
  return new VolumeT(
    (this.xform() !== null ? this.xform()!.unpack() : null),
    (this.bounds() !== null ? this.bounds()!.unpack() : null)
  );
}


unpackTo(_o: VolumeT): void {
  _o.xform = (this.xform() !== null ? this.xform()!.unpack() : null);
  _o.bounds = (this.bounds() !== null ? this.bounds()!.unpack() : null);
}
}

export class VolumeT implements flatbuffers.IGeneratedObject {
constructor(
  public xform: TRXform2D5T|null = null,
  public bounds: Box3T|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Volume.createVolume(builder,
    (this.xform?.translation?.x ?? 0),
    (this.xform?.translation?.y ?? 0),
    (this.xform?.translation?.z ?? 0),
    (this.xform?.orientation ?? 0),
    (this.bounds?.min?.x ?? 0),
    (this.bounds?.min?.y ?? 0),
    (this.bounds?.min?.z ?? 0),
    (this.bounds?.max?.x ?? 0),
    (this.bounds?.max?.y ?? 0),
    (this.bounds?.max?.z ?? 0)
  );
}
}
