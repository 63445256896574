// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { Vector3, Vector3T } from '../fbmath/vector3.js';


export class Box3 implements flatbuffers.IUnpackableObject<Box3T> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):Box3 {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

min(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos, this.bb!);
}

max(obj?:Vector3):Vector3|null {
  return (obj || new Vector3()).__init(this.bb_pos + 12, this.bb!);
}

static sizeOf():number {
  return 24;
}

static createBox3(builder:flatbuffers.Builder, min_x: number, min_y: number, min_z: number, max_x: number, max_y: number, max_z: number):flatbuffers.Offset {
  builder.prep(4, 24);
  builder.prep(4, 12);
  builder.writeFloat32(max_z);
  builder.writeFloat32(max_y);
  builder.writeFloat32(max_x);
  builder.prep(4, 12);
  builder.writeFloat32(min_z);
  builder.writeFloat32(min_y);
  builder.writeFloat32(min_x);
  return builder.offset();
}


unpack(): Box3T {
  return new Box3T(
    (this.min() !== null ? this.min()!.unpack() : null),
    (this.max() !== null ? this.max()!.unpack() : null)
  );
}


unpackTo(_o: Box3T): void {
  _o.min = (this.min() !== null ? this.min()!.unpack() : null);
  _o.max = (this.max() !== null ? this.max()!.unpack() : null);
}
}

export class Box3T implements flatbuffers.IGeneratedObject {
constructor(
  public min: Vector3T|null = null,
  public max: Vector3T|null = null
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  return Box3.createBox3(builder,
    (this.min?.x ?? 0),
    (this.min?.y ?? 0),
    (this.min?.z ?? 0),
    (this.max?.x ?? 0),
    (this.max?.y ?? 0),
    (this.max?.z ?? 0)
  );
}
}
