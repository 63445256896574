// automatically generated by the FlatBuffers compiler, do not modify

/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any, @typescript-eslint/no-non-null-assertion */

import * as flatbuffers from 'flatbuffers';

import { AnnotationType } from '../fbfloorplanner/annotation-type.js';
import { AttachmentType } from '../fbfloorplanner/attachment-type.js';
import { ExteriorType } from '../fbfloorplanner/exterior-type.js';
import { FurnitureType } from '../fbfloorplanner/furniture-type.js';
import { OpenningType } from '../fbfloorplanner/openning-type.js';
import { RegionType } from '../fbfloorplanner/region-type.js';
import { RoomType } from '../fbfloorplanner/room-type.js';


export class AnnotationSemantics implements flatbuffers.IUnpackableObject<AnnotationSemanticsT> {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
  __init(i:number, bb:flatbuffers.ByteBuffer):AnnotationSemantics {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsAnnotationSemantics(bb:flatbuffers.ByteBuffer, obj?:AnnotationSemantics):AnnotationSemantics {
  return (obj || new AnnotationSemantics()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsAnnotationSemantics(bb:flatbuffers.ByteBuffer, obj?:AnnotationSemantics):AnnotationSemantics {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new AnnotationSemantics()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

title():string|null
title(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
title(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

type():AnnotationType {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : AnnotationType.Other;
}

exteriorType():ExteriorType {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : ExteriorType.Other;
}

roomType():RoomType {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : RoomType.Other;
}

openningType():OpenningType {
  const offset = this.bb!.__offset(this.bb_pos, 12);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : OpenningType.Other;
}

attachmentType():AttachmentType {
  const offset = this.bb!.__offset(this.bb_pos, 14);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : AttachmentType.Other;
}

furnitureType():FurnitureType {
  const offset = this.bb!.__offset(this.bb_pos, 16);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : FurnitureType.Other;
}

regionType():RegionType {
  const offset = this.bb!.__offset(this.bb_pos, 18);
  return offset ? this.bb!.readUint32(this.bb_pos + offset) : RegionType.Other;
}

covered():boolean {
  const offset = this.bb!.__offset(this.bb_pos, 20);
  return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
}

static startAnnotationSemantics(builder:flatbuffers.Builder) {
  builder.startObject(9);
}

static addTitle(builder:flatbuffers.Builder, titleOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, titleOffset, 0);
}

static addType(builder:flatbuffers.Builder, type:AnnotationType) {
  builder.addFieldInt32(1, type, AnnotationType.Other);
}

static addExteriorType(builder:flatbuffers.Builder, exteriorType:ExteriorType) {
  builder.addFieldInt32(2, exteriorType, ExteriorType.Other);
}

static addRoomType(builder:flatbuffers.Builder, roomType:RoomType) {
  builder.addFieldInt32(3, roomType, RoomType.Other);
}

static addOpenningType(builder:flatbuffers.Builder, openningType:OpenningType) {
  builder.addFieldInt32(4, openningType, OpenningType.Other);
}

static addAttachmentType(builder:flatbuffers.Builder, attachmentType:AttachmentType) {
  builder.addFieldInt32(5, attachmentType, AttachmentType.Other);
}

static addFurnitureType(builder:flatbuffers.Builder, furnitureType:FurnitureType) {
  builder.addFieldInt32(6, furnitureType, FurnitureType.Other);
}

static addRegionType(builder:flatbuffers.Builder, regionType:RegionType) {
  builder.addFieldInt32(7, regionType, RegionType.Other);
}

static addCovered(builder:flatbuffers.Builder, covered:boolean) {
  builder.addFieldInt8(8, +covered, +false);
}

static endAnnotationSemantics(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createAnnotationSemantics(builder:flatbuffers.Builder, titleOffset:flatbuffers.Offset, type:AnnotationType, exteriorType:ExteriorType, roomType:RoomType, openningType:OpenningType, attachmentType:AttachmentType, furnitureType:FurnitureType, regionType:RegionType, covered:boolean):flatbuffers.Offset {
  AnnotationSemantics.startAnnotationSemantics(builder);
  AnnotationSemantics.addTitle(builder, titleOffset);
  AnnotationSemantics.addType(builder, type);
  AnnotationSemantics.addExteriorType(builder, exteriorType);
  AnnotationSemantics.addRoomType(builder, roomType);
  AnnotationSemantics.addOpenningType(builder, openningType);
  AnnotationSemantics.addAttachmentType(builder, attachmentType);
  AnnotationSemantics.addFurnitureType(builder, furnitureType);
  AnnotationSemantics.addRegionType(builder, regionType);
  AnnotationSemantics.addCovered(builder, covered);
  return AnnotationSemantics.endAnnotationSemantics(builder);
}

unpack(): AnnotationSemanticsT {
  return new AnnotationSemanticsT(
    this.title(),
    this.type(),
    this.exteriorType(),
    this.roomType(),
    this.openningType(),
    this.attachmentType(),
    this.furnitureType(),
    this.regionType(),
    this.covered()
  );
}


unpackTo(_o: AnnotationSemanticsT): void {
  _o.title = this.title();
  _o.type = this.type();
  _o.exteriorType = this.exteriorType();
  _o.roomType = this.roomType();
  _o.openningType = this.openningType();
  _o.attachmentType = this.attachmentType();
  _o.furnitureType = this.furnitureType();
  _o.regionType = this.regionType();
  _o.covered = this.covered();
}
}

export class AnnotationSemanticsT implements flatbuffers.IGeneratedObject {
constructor(
  public title: string|Uint8Array|null = null,
  public type: AnnotationType = AnnotationType.Other,
  public exteriorType: ExteriorType = ExteriorType.Other,
  public roomType: RoomType = RoomType.Other,
  public openningType: OpenningType = OpenningType.Other,
  public attachmentType: AttachmentType = AttachmentType.Other,
  public furnitureType: FurnitureType = FurnitureType.Other,
  public regionType: RegionType = RegionType.Other,
  public covered: boolean = false
){}


pack(builder:flatbuffers.Builder): flatbuffers.Offset {
  const title = (this.title !== null ? builder.createString(this.title!) : 0);

  return AnnotationSemantics.createAnnotationSemantics(builder,
    title,
    this.type,
    this.exteriorType,
    this.roomType,
    this.openningType,
    this.attachmentType,
    this.furnitureType,
    this.regionType,
    this.covered
  );
}
}
