export const isMobile = {
    Android: function() {
        return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function() {
        return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function() {
        return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function() {
        return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function() {
        return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    },
    any: function() {
        return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
};

export const isLocalHost = window.location.hostname === 'localhost';

// Transition from one element to another.
export function transition(from: string, to: string) {
    document.getElementById(from)?.classList.add('hidden');
    document.getElementById(to)?.classList.remove('hidden');
}

export function setText(className: string, text: string) {
    var elements = document.getElementsByClassName(className);
    Array.from(elements).forEach((element) => {
        if (element instanceof HTMLImageElement) {
            element.src = text;
        } else if (element instanceof HTMLElement) {
            element.innerHTML = text;
        }
    });
}